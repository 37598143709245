<template>
    <div class="footer w-100">
        <audio id="audio_zen" v-show="false">
            <source
                v-for="(track, index) of global.ZEND_SOUNDS"
                :key="index"
                :data-track-number="index"
                :src="`${global.ZEND_MUSIC_URI}${track}`"
                type="audio/mpeg"
            />
        </audio>
        <SaveConfUtil />
        <div class="d-flex justify-space-around align-center" v-if="currentStage === 'bookReading'">
            <div>
                <x-ArrowButtonOutline :width="'190px'" :height="'10vh'" @brClick="back">
                    <span class="secondary--text text-body-2 font-weight-bold">
                        {{ $t('go_back') }}
                    </span>
                </x-ArrowButtonOutline>
            </div>
            <div class="py-2 px-3 bg-white shadow-lg border-radius-2">
                <span class="text-upppercase text-center footer-text">
                    {{ currentPage }}
                    <span class="opacity-6">
                        {{ $t('of') }} {{ structTotalPages }}
                    </span>
                </span>
            </div>
            <div>
                <x-ArrowButton :width="'200px'" :height="'10vh'" @brClick="next">
                    <span class="white--text text-body-2 font-weight-bold">
                        {{ $t('next') }}
                    </span>
                </x-ArrowButton>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import global from '@/constants/GlobalConstant'

import SaveConfUtil from './bookUtils/utils/SaveConfUtils.vue'

export default {
    components: {
        SaveConfUtil,
    },
    computed: {
        ...mapState({
            currentStage: (state) => state.bookReading.currentStage,
            currentPage: (state) => state.bookReading.currentPage,
            structTotalPages: (state) => state.bookReading.book.structTotalPages,
            book: (state) => state.bookReading.book,
            user: (state) => state.userData.user,
            currentChapter: (state) => state.bookReading.currentChapter,
            currentChapterData: (state) => state.bookReading.currentChapterData,
            bookAssessmentData: (state) => state.bookReading.bookAssessmentData,
            questionsForChapter: (state) => state.bookReading.questionsForChapter,
            itemsData: (state) => state.bookReading.itemsData,
            tipsData: (state) => state.bookReading.tipsData,
            indexItem: (state) => state.bookReading.indexItem,
            itemType: (state) => state.bookReading.itemType,
            currentQuestion: (state) => state.bookReading.currentQuestion,
            indexQuestion: (state) => state.bookReading.indexQuestion,
            hasQuestion: (state) => state.bookReading.hasQuestion,
        }),
    },
    data() {
        return {
            seconds: 1,
            global,
            zenIndex: 0,
        }
    },
    methods: {
        ...mapActions('bookReading', [
            'increaseCurrentPage',
            'decreaseCurrentPage',
            'setCurrentChapter',
            'setCurrentChapterData',
            'setCurrentStage',
            'setItemsData',
            'setItemType',
            'setQuestionForChapter',
            'setCurrentQuestion',
            'showQuestions',
            'setHasQuestion',
            'setLastQuestionOfGroup',
            'setLastQuestionOfBook',
        ]),
        next() {
            if (this.hasQuestion) {
                this.showQuestions()
                return
            }
            window.scrollTo(0, 0)
            let bookPage = document.querySelector(`#bookpage_${this.currentPage}`)
            if (bookPage) bookPage.style.display = 'none'

            if (this.currentPage === this.book.structTotalPages) {
                this.setCurrentStage('bookEnd')
                return
            }
            this.increaseCurrentPage()

            if (this.currentPage > this.currentChapterData.structFinalPage) {
                let chapterHtml = document.querySelector(`#chapter_${this.currentChapter}`)
                if (chapterHtml) chapterHtml.style.display = 'none'

                this.setCurrentChapter(this.currentChapter + 1)
                chapterHtml = document.querySelector(`#chapter_${this.currentChapter}`)
                if (chapterHtml) chapterHtml.style.display = 'flex'

                bookPage = document.querySelector(`#bookpage_${this.currentPage}`)
                if (bookPage) bookPage.style.display = 'flex'

                const currentPage = document.querySelector('.current-page')
                if (currentPage) currentPage.classList.toggle('current-page')

                if (bookPage) bookPage.classList.toggle('current-page')
                this.setCurrentChapterData(this.book.bookChapterDTOList[this.currentChapter - 1])
            } else {
                const currentPage = document.querySelector('.current-page')
                if (currentPage) currentPage.classList.toggle('current-page')

                bookPage = document.querySelector(`#bookpage_${this.currentPage}`)
                if (bookPage) {
                    bookPage.classList.toggle('current-page')
                    bookPage.style.display = 'flex'
                }
            }

            this.seconds = 1
            this.getQuestionsForPage()
        },
        back() {
            if (this.currentPage === this.book.bookChapterDTOList[0].structInitialPage) {
                this.$modal.error('Estas en la primera página del libro.')
                return
            }
            window.scrollTo(0, 0)
            let bookPage = document.querySelector(`#bookpage_${this.currentPage}`)
            if (bookPage) bookPage.style.display = 'none'
            this.decreaseCurrentPage()

            if (this.currentPage < this.currentChapterData.structInitialPage) {
                let chapterHtml = document.querySelector(`#chapter_${this.currentChapter}`)
                if (chapterHtml) chapterHtml.style.display = 'none'

                this.setCurrentChapter(this.currentChapter - 1)
                chapterHtml = document.querySelector(`#chapter_${this.currentChapter}`)
                if (chapterHtml) chapterHtml.style.display = 'flex'

                bookPage = document.querySelector(`#bookpage_${this.currentPage}`)
                if (bookPage) bookPage.style.display = 'flex'

                const currentPage = document.querySelector('.current-page')
                if (currentPage) currentPage.classList.toggle('current-page')

                if (bookPage) bookPage.classList.toggle('current-page')

                this.setCurrentChapterData(this.book.bookChapterDTOList[this.currentChapter - 1])
            } else {
                const currentPage = document.querySelector('.current-page')
                if (currentPage) currentPage.classList.toggle('current-page')

                bookPage = document.querySelector(`#bookpage_${this.currentPage}`)
                if (bookPage) {
                    bookPage.classList.toggle('current-page')
                    if (bookPage) bookPage.style.display = 'flex'
                }
            }
            this.seconds = 1
            this.setHasQuestion(false)
        },
        getQuestionsForPage() {
            const vm = this
            this.setItemsData(
                this.bookAssessmentData.filter(
                    (element) => element.fragment.structChapterPage === vm.currentPage
                )
            )
            if (this.itemsData.length > 0) {
                this.setItemType(this.itemsData[this.indexItem].itemType)
                this.setQuestionForChapter(this.itemsData[this.indexItem].questions)
                this.questionsForChapter.sort((a, b) => this.orderQuestions(a, b))
                this.setCurrentQuestion(this.questionsForChapter[this.indexQuestion])
                const index = this.bookAssessmentData.indexOf(this.itemsData[this.indexItem])
                this.setLastQuestionOfGroup(this.indexItem === this.itemsData.length - 1)
                this.setLastQuestionOfBook(index === this.bookAssessmentData.length - 1)
                this.setHasQuestion(true)
                return
            }

            this.setHasQuestion(false)
        },
        orderQuestions(a, b) {
            if (a.itemVariant > b.itemVariant) {
                return 1
            }
            if (a.itemVariant < b.itemVariant) {
                return -1
            }
            return 0
        },
    },
}
</script>
