<template>
  <v-col cols="12" order="last" class="pa-0">
    <v-tabs
      v-model="tab"
      :class="`${flat ? 'mb-10' : ''}`"
      :height="height"
      :slider-size="tabSize"
      :slider-color="tabColor"
      @change="change"
      background-color="transparent"
      v-if="items"
      :vertical="vertical"
    >
      <v-tab
        v-for="(item, index) in items"
        :key="itemsHaveIcons ? item.text : item"
        :class="`text-${textAlign} no-uppercase text-subtitle-1 d-inline-block mr-4`"
        active-class="font-weight-medium"
        :disabled="disabled"
      >
        <div v-if="itemsHaveIcons" :class="`text-center d-flex align-center justify-space-around ${ itemIsSelected(index) ? 'text--primary' : 'text--grey'}`">
          <v-icon :color="`${itemIsSelected(index) ? 'primary' : 'grey'}`" v-html="item.icon" />
          <span :class="`ml-4 mr-4 text--primary ${ itemIsSelected(index) ? 'font-weight-medium' : ''}`"> {{ item.text }} </span>
          <v-tooltip color="white" content-class="px-6 py-6 elevation-12 rounded-lg" max-width="270px" v-if="itemsHaveTooltip" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="`${itemIsSelected(index) ? 'primary' : 'grey'}`"
                dense
                v-bind="attrs"
                v-on="on"
              >
                {{ item.tooltip.icon }}
              </v-icon>
            </template>
            <span class="font-weight-regular black--text">{{ item.tooltip.message }}</span>
          </v-tooltip>
        </div>
        <div v-else>
          <span :class="`text-no-wrap`" v-html="item" />
        </div>
      </v-tab>
    </v-tabs>
  </v-col>
</template>

<script>
export default {
  name: 'BeeTabs',
  props: {
    items: { type: Array, default: null },
    tabColor: { type: String, default: 'primary' },
    textAlign: { type: String, default: 'center' },
    height: { type: [Number, String], default: '35' },
    tabSize: { type: [Number, String], default: '6' },
    flat: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    vertical: { type: Boolean, default: false },
    tabSelected: { type: [Number, String], default: 0 },
  },
  data() {
    return {
      tab: 0,
    }
  },
  computed: {
    itemsHaveIcons() {
      return this.items.some((item) => item.icon != null)
    },
    itemsHaveTooltip() {
      return this.items.some((item) => item.tooltip != null)
    },
  },
  created() {
    if (this.tabSelected >= 0) {
      this.tab = this.tabSelected
    }
  },
  methods: {
    change() {
      this.$emit('changeTab', this.tab)
    },
    selectTab(tabNumber) {
      this.tab = tabNumber
    },
    itemIsSelected(index) {
      return this.tab === index
    }
  },
  watch: {
    tabSelected: {
      handler(val) {
        this.tab = val
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.no-uppercase {
  text-transform: none;
}
.text-subtitle-1{
  font-family: 'Poppins', cursive, sans-serif !important;
  font-size: 0.9rem !important;
  line-height: 1rem;
}
</style>
