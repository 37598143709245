import { Student } from '@/data/dto/student'
import { EvaluationFullData } from '@/store/modules/Diagnoses/types'
import {
    DiagnosisResultsPreviewData,
    EvaluationSimpleData,
    EvaluationText,
} from '@/store/modules/Fluency/types'
import Services from '../base-services'

const service = new Services('fluency', { fluency: true })

export default {
    async getRecordingUploadUrl(payload: { evaluationId: number; studentId: number}) {
        const data = (await service.post(
            `evaluation_upload_url?evaluationId=${payload.evaluationId}&studentId=${payload.studentId}`
        )) as {
            id: string
            user_id: number
            evaluation_id: number
            recording_url: string
            upload_url: string
        }
        return data
    },
    async saveEvaluationRecord(audioBlob: Blob, evaluationId: string, studentId: string) {
        const file = new File([audioBlob], `audio-by-${studentId}-evaluation-${evaluationId}.wav`, {
            type: 'audio/wav',
        })

        const data = (await service.postWithFile(
            `?evaluationId=${evaluationId}&studentId=${studentId}`,
            {
                file,
            }
        )) as {
            id: string
            user_id: string
            evaluation_id: string
            recording_url: string
        }
        return data
    },
    async evaluate(payload: { recordingUrl: string; evaluationId: number; studentId: number, audioDuration: number }) {
        const data = (await service.post('evaluate', {
            recording_url: payload.recordingUrl,
            evaluation_id: payload.evaluationId,
            student_id: payload.studentId,
            audio_duration: payload.audioDuration,
        })) as DiagnosisResultsPreviewData
        return data
    },
    async getEvaluationResults(evaluationId: string) {
        const data = (await service.get(`evaluation/results/${evaluationId}`)) as DiagnosisResultsPreviewData & {
            student: Student
        }
        return data
    },
    async updateDiagnosis(payload: { diagnosis: DiagnosisResultsPreviewData }) {
        const data = await service.post('evaluation/update', {
            student_evaluation_id: payload.diagnosis.id,
            ...payload.diagnosis,
        }) as DiagnosisResultsPreviewData
        return data
    },
    async deleteEvaluation(diagnosisID: number) {
        const data = await service.post(`evaluation/delete/${diagnosisID}`)
        return data.deleted
    },
    async deleteStudentResults(resultsID: number) {
        const data = await service.post(`evaluation/results/delete/${resultsID}`) as {
            deleted: boolean,
            message: string
        }
        return data.deleted
    },
    async getEvaluationAssignmentsByTeacher(teacherID: number, courseID: number) {
        const data = await service.get(`evaluation_assignments/teacher/${teacherID}/course/${courseID}`) as {
            evaluations: EvaluationSimpleData[]
        }
        return data.evaluations
    },
    async addEvaluationAssignment(course_id: number, text_id: number, teacher_id: number) {
        const resp = await service.post('evaluation_assignments/add', {
            course_id,
            text_id,
            teacher_id,
        })

        if(!resp) {
            throw new Error('Error al asignar la evaluación')
        }

        if(resp.code) {
            throw new Error(resp.message)
        }

        return resp.evaluation
    },
    async getEvaluationAssignmentDetail(payload: { evaluationId: number, teacherId: number }) {
        const data = await service.get(`evaluation_assignments/${payload.evaluationId}/teacher/${payload.teacherId}`)
        return data as EvaluationSimpleData & { students: Student[] }
    },
    async getEvaluationsTexts() {
        const data = (await service.get('evaluation_texts')) as { texts: EvaluationText[] }
        return data.texts
    },
    async getEvaluationsText(evaluationTextId: number) {
        const data = (await service.get(`evaluation_texts/${evaluationTextId}`)) as { text: EvaluationText }
        return data.text
    },
    async getEvaluationTextResults(paylaod: { evaluationTextID: number, courseId: number }) {
        const data = (await service.get(`evaluation_text/${paylaod.evaluationTextID}/course/${paylaod.courseId}/results`)) as {
            results: EvaluationFullData[]
        }
        return data
    },
    async getCourseEvaluationResultsTexts(courseID: number) {
        const data = await service.get(`evaluation/results/texts/course/${courseID}`) as { course_name: string, evaluation_id: number, evaluation_texts: {id: number, title: string, }[]}
        return data
    }
}
