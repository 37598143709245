export default {
    NOV: 'Novela',
    CUE: 'Cuento',
    MIT: 'Mito',
    LEY: 'Leyenda',
    FAB: 'Fábula',
    TEA: 'Teatro',
    EPI: 'Epístola',
    INF: 'Informativo',
    BIO: 'Biografía',
    POE: 'Poema',
    COM: 'Cómic',
    ROM: 'Romance',
    TRA: 'Tragedia',
    ART: 'Artículo',
    CRO: 'Crónica',
    ENS: 'Ensayo',
    CAR: 'Epistolar (carta)',
    REC: 'Receta',
    EPC: 'Épica',
    ENT: 'Entrevistas',
    EXP: 'Experimentos'
}
