import { ActionContext } from 'vuex'
import {
    DiagnosisResultsFullData,
    DiagnosisResultsPreviewData,
    DIAGNOSIS_ERROR_TYPE,
    DIAGNOSIS_EVALUATION_STATE,
    DIAGNOSIS_EVALUATION_TYPE,
    DIAGNOSIS_READING_QUALITY,
    DIAGNOSIS_STATE,
    DiagnosticsGlobalState,
    EvaluationFullData,
    EvaluationSimpleData,
    EvaluationText,
    StudentWithDiagnosis,
} from './types'
import fluencyServices from '@/services/fluency'
import courseService from '@/services/course'
import { Student } from '@/data/dto/student'

const defaultState = (): DiagnosticsGlobalState => ({
    currentCourseResultsID: null,
    currentCourseTextResultsID: null,
    currentAssignedEvaluationDetail: null,
    assignedEvaluations: [],
    newEvaluationData: undefined,
    diagnosisResultsPreview: null,
    diagnosisResultsDetail: null,
    evaluationsTexts: [],
    filters: {
        currentCourseID: undefined,
        currentEvaluationType: undefined,
        evaluationState: undefined,
    },
    errorsType: [
        {
            id: 1,
            errorType: DIAGNOSIS_ERROR_TYPE.WORD_SUBSTITUTION,
        },
        {
            id: 2,
            errorType: DIAGNOSIS_ERROR_TYPE.WORD_INSERTION,
        },
        {
            id: 3,
            errorType: DIAGNOSIS_ERROR_TYPE.WORD_REPETITION,
        },
        {
            id: 4,
            errorType: DIAGNOSIS_ERROR_TYPE.WORD_OMISSION,
        },
        {
            id: 5,
            errorType: DIAGNOSIS_ERROR_TYPE.DICTION_ERROR,
        },
    ],
    readingQualityOptions: [
        {
            id: 1,
            name: DIAGNOSIS_READING_QUALITY.NO_READER,
        },
        {
            id: 2,
            name: DIAGNOSIS_READING_QUALITY.SYLLABIC_READING,
        },
        {
            id: 3,
            name: DIAGNOSIS_READING_QUALITY.WORD_BY_WORD_READING,
        },
        {
            id: 4,
            name: DIAGNOSIS_READING_QUALITY.SHORT_UNITS_READING,
        },
        {
            id: 5,
            name: DIAGNOSIS_READING_QUALITY.FLUENT_READING,
        },
    ],
})

const diagnosticData = {
    namespaced: true,
    state: defaultState(),
    getters: {
        currentDiagnostic: (state: DiagnosticsGlobalState) => {
            return state.currentAssignedEvaluationDetail
        },
        assignedEvaluations: (state: DiagnosticsGlobalState) => {
            return state.assignedEvaluations
        },
        getCourseSelected: (state: DiagnosticsGlobalState) => {
            return state.filters.currentCourseID
        },
        getDiagnosticEvaluationType: (state: DiagnosticsGlobalState) => {
            return state.filters.currentEvaluationType
        },
        getDiagnosticEvaluationState: (state: DiagnosticsGlobalState) => {
            return state.filters.evaluationState
        },
        getCurrentResults: (state: DiagnosticsGlobalState) => {
            if (state.diagnosisResultsDetail) return state.diagnosisResultsDetail
            return state.diagnosisResultsPreview
        },
        getErrorsTypes(state: DiagnosticsGlobalState) {
            return state.errorsType
        },
        getErrorTypeByID: (state: DiagnosticsGlobalState) => (id: number) => {
            return state.errorsType.find((error) => error.id === id)
        },
        getReadingQualityOptions(state: DiagnosticsGlobalState) {
            return state.readingQualityOptions
        },
        getReadingQualityOptionByID: (state: DiagnosticsGlobalState) => (id: number) => {
            return state.readingQualityOptions.find((option) => option.id === id)
        },
        currentEvaluationText: (state: DiagnosticsGlobalState) => {
            return state.evaluationsTexts.find(
                (text) => text.id === state.currentAssignedEvaluationDetail?.text_id
            )
        },
    },
    mutations: {
        async setAssignedEvaluations(
            state: DiagnosticsGlobalState,
            payload: { evaluations: EvaluationSimpleData[] }
        ) {
            state.assignedEvaluations = payload.evaluations
        },
        async setDiagnosticEvaluationFullData(
            state: DiagnosticsGlobalState,
            payload: { fullEvaluation: EvaluationFullData }
        ) {
            state.currentAssignedEvaluationDetail = payload.fullEvaluation
        },
        addStudentDiagnosisToEvaluationDetail(
            state: DiagnosticsGlobalState,
            payload: { studentDiagnosis: any }
        ) {
            const student = state.currentAssignedEvaluationDetail?.students.find(
                (student: any) => student.id === payload.studentDiagnosis.student_id
            ) as any
            if (!student) return
            student['diagnosis'] = payload.studentDiagnosis.diagnosis
        },
        updateStudentDiagnosisInEvaluationDetail(
            state: DiagnosticsGlobalState,
            payload: { studentDiagnosis: DiagnosisResultsPreviewData }
        ) {
            const student = state.currentAssignedEvaluationDetail?.students.find(
                (student: any) => student.id === payload.studentDiagnosis.student_id
            ) as any
            if (!student) return
            student['diagnosis'] = payload.studentDiagnosis
        },
        addNewEvaluationIntoAssigned(
            state: DiagnosticsGlobalState,
            payload: { evaluation: EvaluationSimpleData }
        ) {
            state.assignedEvaluations.push(payload.evaluation)
        },
        removeEvaluationFromAssigned(
            state: DiagnosticsGlobalState,
            payload: { evaluationID: number }
        ) {
            state.assignedEvaluations = state.assignedEvaluations.filter(
                (ev) => ev.id !== payload.evaluationID
            )
        },
        removeEvaluationResult(state: DiagnosticsGlobalState, payload: { resultsID: string }) {
            if (!state.currentAssignedEvaluationDetail) return
            const studentWithDiagnosisToDel = state.currentAssignedEvaluationDetail.students.find(
                (student: any) => student.diagnosis?.id === payload.resultsID
            ) as any
            if (!studentWithDiagnosisToDel) return
            studentWithDiagnosisToDel.diagnosis = null
        },
        resetFilters(state: DiagnosticsGlobalState) {
            state.filters = defaultState().filters
        },
        setCourseSelected(state: DiagnosticsGlobalState, payload: { courseID: number }) {
            state.filters.currentCourseID = payload.courseID
        },
        setDiagnosticEvaluationType(
            state: DiagnosticsGlobalState,
            payload: { evaluationType: DIAGNOSIS_EVALUATION_TYPE }
        ) {
            state.filters.currentEvaluationType = payload.evaluationType
        },
        setDiagnosticEvaluationState(
            state: DiagnosticsGlobalState,
            payload: { evaluationState: DIAGNOSIS_EVALUATION_STATE }
        ) {
            state.filters.evaluationState = payload.evaluationState
        },
        setDiagnosticResultsPreview(
            state: DiagnosticsGlobalState,
            payload: { resultsPreview: DiagnosisResultsPreviewData }
        ) {
            state.diagnosisResultsPreview = payload.resultsPreview
        },
        setDiagnosticResultsDetail(
            state: DiagnosticsGlobalState,
            payload: { resultsDetail: DiagnosisResultsFullData }
        ) {
            state.diagnosisResultsDetail = payload.resultsDetail
        },
        // EVALUATION TEXTS
        setEvaluationTexts(state: DiagnosticsGlobalState, payload: { texts: EvaluationText[] }) {
            state.evaluationsTexts = payload.texts
        },
        setCurrentCourseResultsID(state: DiagnosticsGlobalState, payload: { courseID: number }) {
            state.currentCourseResultsID = payload.courseID
        },
        setCurrentCourseTextResultsID(state: DiagnosticsGlobalState, payload: { textID: number }) {
            state.currentCourseTextResultsID = payload.textID
        },
    },
    actions: {
        async getAssignedEvaluations(
            { commit, state }: ActionContext<DiagnosticsGlobalState, any>,
            payload: { teacherID: number, courseID: number }
        ) {
            const evaluations = await fluencyServices.getEvaluationAssignmentsByTeacher(payload.teacherID, payload.courseID)
            commit('setAssignedEvaluations', { evaluations: evaluations })
        },
        async getDiagnosisDetail(
            { commit, state }: ActionContext<DiagnosticsGlobalState, any>,
            payload: { diagnosisID: number; teacherID: number }
        ) {
            const evaluationResponse = await fluencyServices.getEvaluationAssignmentDetail({
                evaluationId: payload.diagnosisID,
                teacherId: payload.teacherID,
            })

            const evaluation: EvaluationFullData = {
                ...(evaluationResponse as EvaluationFullData),
            }
            commit('setDiagnosticEvaluationFullData', { fullEvaluation: evaluation })
        },
        async getDiagnosisResultsPreview(
            { commit }: ActionContext<DiagnosticsGlobalState, any>,
            payload: { diagnosisID: number }
        ) {
            const resultsPreview = await fluencyServices.getEvaluationResults(
                String(payload.diagnosisID)
            )
            commit('setDiagnosticResultsPreview', { resultsPreview: resultsPreview })
            return resultsPreview
        },
        async evaluate(
            { commit, state }: ActionContext<DiagnosticsGlobalState, any>,
            payload: {
                evaluationId: number
                studentID: number
                audioFile: Blob
                audioDuration: number
            }
        ) {
            try {
                const audio_resp = await fluencyServices.getRecordingUploadUrl({
                    evaluationId: payload.evaluationId,
                    studentId: payload.studentID,
                })
                await fetch(audio_resp.upload_url, {
                    method: 'PUT',
                    body: payload.audioFile,
                    headers: {
                        'Content-Type': 'audio/wav',
                    },
                })

                const studentDiagnosis = await fluencyServices.evaluate({
                    recordingUrl: audio_resp.recording_url,
                    evaluationId: payload.evaluationId,
                    studentId: payload.studentID,
                    audioDuration: payload.audioDuration,
                })
                commit('setDiagnosticResultsDetail', { resultsDetail: studentDiagnosis })
                commit('addStudentDiagnosisToEvaluationDetail', { studentDiagnosis })

                const student = state.currentAssignedEvaluationDetail?.students.find(
                    (student: any) => student.id === payload.studentID
                ) as any
                if (student) {
                    student.diagnosis = studentDiagnosis
                }
                return studentDiagnosis
            } catch (error) {
                console.log(error)
                throw new Error('Error evaluating the student')
            }
        },
        async updateDiagnosis(
            { commit }: ActionContext<DiagnosticsGlobalState, any>,
            payload: { diagnosis: DiagnosisResultsPreviewData }
        ) {
            const diagnosis = await fluencyServices.updateDiagnosis({
                diagnosis: payload.diagnosis,
            })
            commit('updateStudentDiagnosisInEvaluationDetail', { studentDiagnosis: diagnosis })
            return diagnosis
        },
        async getDiagnosisResultsDetail(
            { commit, state }: ActionContext<DiagnosticsGlobalState, any>,
            payload: { diagnosisID: string; teacherID: number }
        ) {
            const resultsDetail = await fluencyServices.getEvaluationResults(payload.diagnosisID)
            await commit('setDiagnosticResultsDetail', { resultsDetail: resultsDetail })

            if (
                !state.assignedEvaluations.length ||
                !state.assignedEvaluations.find((ev) => ev.id === resultsDetail.evaluation_id)
            ) {
                const evaluations = await fluencyServices.getEvaluationAssignmentsByTeacher(
                    payload.teacherID, 0
                )
                await commit('setAssignedEvaluations', { evaluations: evaluations })
            }

            if (!state.currentAssignedEvaluationDetail) {
                const evaluationResponse = await fluencyServices.getEvaluationAssignmentDetail({
                    evaluationId: resultsDetail.evaluation_id,
                    teacherId: payload.teacherID,
                })
                await commit('setDiagnosticEvaluationFullData', {
                    fullEvaluation: evaluationResponse,
                })
            }
            return resultsDetail
        },
        async getEvaluationTexts({ commit }: ActionContext<DiagnosticsGlobalState, any>) {
            const texts = await fluencyServices.getEvaluationsTexts()
            commit('setEvaluationTexts', { texts })
            return texts
        },
        async addEvaluationAssignment(
            { commit }: ActionContext<DiagnosticsGlobalState, any>,
            payload: { courseId: number; textId: number; teacherId: number }
        ) {
            const evaluation = await fluencyServices.addEvaluationAssignment(
                payload.courseId,
                payload.textId,
                payload.teacherId
            )
            commit('addNewEvaluationIntoAssigned', { evaluation })
        },
        async deleteStudentDiagnosis(
            { commit }: ActionContext<DiagnosticsGlobalState, any>,
            payload: { resultsID: number }
        ) {
            const deleted = await fluencyServices.deleteStudentResults(payload.resultsID)
            if (!deleted) return
            commit('removeEvaluationResult', { resultsID: payload.resultsID })
        },
        async getResultsFromEvaluationText(
            { commit }: ActionContext<DiagnosticsGlobalState, any>,
            payload: { evaluationTextID: number; courseId: number }
        ) {
            const results = await fluencyServices.getEvaluationTextResults(payload)
            return results
        },
        async getResultsFromEvaluation(
            { commit }: ActionContext<DiagnosticsGlobalState, any>,
            payload: { evaluationID: number; teacherID: number }
        ) {
            const evaluationResponse = await fluencyServices.getEvaluationAssignmentDetail({
                evaluationId: payload.evaluationID,
                teacherId: payload.teacherID,
            })
            return evaluationResponse
        },
        async deleteEvaluationAssignment(
            { commit }: ActionContext<DiagnosticsGlobalState, any>,
            payload: { evaluationID: number }
        ) {
            const deleted = await fluencyServices.deleteEvaluation(payload.evaluationID)
            if (!deleted) return
            commit('removeEvaluationFromAssigned', { evaluationID: payload.evaluationID })
        },
    },
}

export default diagnosticData
